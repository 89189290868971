<div  class="page-title-area">
    <div  class="container">
       <div class="page-title-content text-center">
          <h1 >Contact Us</h1>
          <div  class="row justify-content-center">
             <div  class="col-lg-6 col-sm-6 col-md-6">
                <div class="single-contact-info-box">
                    <div class="icon"><i class="bx bx-map"></i></div>
                    <h3>Address:</h3>
                    <p>LeanQubit Private Limited B-205, , Mastermind-III Royal Palms Aarey Colony, Goregaon (East)Mumbai Maharashtra India 400065</p>
                </div>
             </div>
             <div  class="col-lg-6 col-sm-6 col-md-6">
                <div  class="single-contact-info-box">
                   <div class="icon"><i  class="bx bx-time-five"></i></div>
                   <h3 >Working Hours:</h3>
                   <p > Open Mon-Fri <br> From 10:00 AM to 08:00 PM </p> 
                </div>
             </div>
             <div class="col-lg-6 col-sm-6 col-md-6">
                <div  class="single-contact-info-box">
                   <div class="icon"><i  class="bx bx-envelope"></i></div>
                   <h3 >Email Address:</h3>
                   <p ><a href="mailto:rajivkumar@leanqubit.ai">rajivkumar@leanqubit.ai</a><br >
                    <!-- <a href="mailto:bizops@OpteBiz.com">bizops@OpteBiz.com</a></p> -->
                </div>
             </div>
             <div class="col-lg-6 col-sm-6 col-md-6">
                <div  class="single-contact-info-box">
                   <div  class="icon"><i  class="bx bx-phone-call"></i></div>
                   <h3 >Contact:</h3>
                   <p ><a  href="tel:+1 (419) 476-4520">+1 (419) 476-4520</a><br >
                    <!-- <a  href="tel:+1 (419) 476-4520">+1 (419) 476-4520</a></p> -->
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 <div class="contact-form">
    <div  class="section-title">
       <h2 >Let’s get in touch!</h2>
       <p >We really appreciate your feedback!</p>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <div class="contact-image" *ngFor="let Image of contactImage;">
                <img [src]="Image.img" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="contact-form">
                <form id="contactForm">
                    <div class="row" [formGroup]="form">
                        
                        <form action="javascript:void(0)" (submit)="send()" >
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" name="name" class="form-control" id="name" placeholder="Your name" formControlName="from_name" required>
                                <div *ngIf="form.controls.from_name.invalid && form.controls.from_name.touched" style="color: red;text-align: center;">Name is required</div>

                            </div>
                        </div>
                        <div class="col-lg-12 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" name="email" class="form-control" id="email" placeholder="Your email address" formControlName="from_email" ngModel [email]="true" required >
                                <div *ngIf="form.controls.from_email.invalid && form.controls.from_email.touched" style="color: red;text-align: center; ">Email is required
                                    </div>
                               <!--  -->
                            
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <input type="number" name="phone_number" class="form-control" id="phone_number" placeholder="Your phone number" formControlName="contact" required >
                                <div *ngIf="form.controls.contact.invalid && form.controls.contact.touched" style="color: red;text-align: center;">contact is required</div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <input type="text" name="subject" class="form-control" id="subject" placeholder="Write subject" formControlName="subject" required>
                                <div *ngIf="form.controls.subject.invalid && form.controls.subject.touched" style="color: red;text-align: center;">subject is required</div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="message" id="message" class="form-control" cols="30" rows="6" placeholder="Write your message(Optional)" formControlName="message"></textarea>
                                
                            </div>
                        </div>
                    <div  class="col-lg-12 col-md-12">
                      
                    </div>
                   
                    <ngx-recaptcha2 #captchaElem
                    [siteKey]="siteKey">
                  </ngx-recaptcha2>
                  <br>
                        <div class="col-lg-12 col-md-12">
                            <button  [disabled] = "!form.valid" type="submit" clicked = true; class="default-btn" ><i class="flaticon-tick"></i>Send Message</button>
                        </div>
                </form>
                    </div>
              </form>
               
            </div>
        </div>
    </div>
 </div>
 <div id="map">
    <iframe 
    src="https://www.google.com/maps/embed/v1/place?q=B-205,Mastermind-III+Royal+Palms+Aarey+Colony,+Goregaon+(East),+Mumbai,+Maharashtra,+India+400065&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" 
   
    loading="lazy">
  </iframe>
</div>
