<app-homeone-banner></app-homeone-banner>

<app-homeone-about></app-homeone-about>

<app-homeone-partner></app-homeone-partner>
<section class="app-solutions pt-40 pb-70">
<app-solutions></app-solutions>
</section>


<app-homeone-blog></app-homeone-blog>
<section class="app-solutions pt-70 ">
<app-service-ia></app-service-ia>
</section>

<app-homeone-services></app-homeone-services>

<!-- <section class="process-area pt-100 pb-70"> -->
    <app-homeone-process></app-homeone-process>
<!-- </section> -->

<app-homeone-case-studies></app-homeone-case-studies>

<!-- <section class="scientist-area  pt-100 pb-70">
    <app-homeone-scientist></app-homeone-scientist>
</section> -->


<!-- <section class="process-area pt-100 pb-40"> -->
    
    <!-- </section> -->
<app-homeone-testimonials></app-homeone-testimonials>


<!-- <app-subscribe></app-subscribe> -->
<app-project-start></app-project-start>