<div  class="site-main- clearfix">
    <div class="hero-section yb-section">
       <div class="headline text-center">
          <div class="container">
             <div class="left-side">
                <h1><span class="gradient-word">AWS</span> Integrated Cloud MES Integration</h1>
                <div class="subheading"> LeanQubit Facto Tools are seamlessly integrated with AWS, providing users with a powerful and scalable manufacturing execution system in the cloud. This collaboration leverages the robust infrastructure of Amazon Web Services, ensuring optimal performance, security, and accessibility for businesses adopting MES solutions. Now, organizations can harness the benefits of LeanQubit Facto Tools on AWS, combining operational excellence with the reliability and flexibility of cloud technology for an enhanced manufacturing experience.
                </div>
             </div>
             <div class="right-side" style="top: -35px;"> <img src="assets\img\OptebizImage\aws flow Updated latest.svg" alt="AWS" title="AWS"  style="top: 54px;" class="wow animate__animated animate__fadeInDown"></div>
          </div>
          <div class="container">
            <div class="cards">
               <div class="card">
                  <h3>Collect Data</h3>
                  <div class="short-detail" > We analyze your current operations and systems involved and any previous data-related work.</div>
               </div>
               <div class="card">
                  <h3>Organize Data</h3>
                  <div class="short-detail" > Find the areas that need help the most – filling gaps present from previous solutions, or assessing a solution from scratch.</div>
               </div>
               <div class="card">
                   <h3>Analyze Data</h3>
                   <div class="short-detail" >Work with your team to implement our solutions, and guide you through the system – ensuring the product is built around you, for you.</div>
                </div>
            </div>
         </div>
       </div>
    </div>
    <div class="now-available-section yb-section">
       <section class="section side-content">
        <div class="c-row">
           <h2><span>LeanQubit Cloud Solutions positively influence your financial</span></h2>
           <p style="padding-bottom: 60px;">By leveraging LeanQubit AWS cloud-based offerings, your organization gains operational efficiencies with cost-effectiveness. The seamless integration of LeanQubit Cloud Solutions optimizes resource utilization while reducing infrastructure costs, and enhancing overall productivity.</p>
           <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
               <div class="box-wrap">
                  <div class="icon">
                     <i class="bx bx-sitemap blue-clr-bg"></i>
                    
                  </div>
                  <h4 class="number">01</h4>
                  <h4><a>AWS IoT SiteWise</a>
                  </h4>
                  <p>AWS IoT Sitewise unlocks data from industrial equipment to deliver an organized view of live and historical operational insights
                  </p>
                  
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-md-0 mt-4">
               <div class="box-wrap">
                  <div class="icon">
                     <i class="fa fa-bar-chart primary-clr-bg"></i>
                  </div>
                  <h4 class="number">02</h4>
                  <h4><a>AWS IoT Greengrass</a>
                  </h4>
                  <p>It allows devices to gather and analyze data locally, respond autonomously to nearby events, and communicate securely within local networks
                  </p>
                 
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-lg-0 mt-4">
               <div class="box-wrap">
                  <div class="icon">
                     <i class="fa fa-user-secret green-clr-bg"></i>
                  </div>
                  <h4 class="number">03</h4>
                  <h4> <a>Security & Policy</a>
                  </h4>
                  <p>Set up and manage device and data security, and oversee authentication and authorization to control access to components and services in your AWS IoT solution
                  </p>
                 
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
               <div class="box-wrap">
                  <div class="icon">
                     <i class="fa fa-database primary-clr-bg"></i>
                  </div>
                  <h4 class="number">04</h4>
                  <h4><a>Amazon S3 Buckets</a>
                  </h4>
                  <p>Amazon S3 offers management features to optimize, organize, and configure data access according to your specific business, organizational, and compliance needs
                  </p>
                  
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
               <div class="box-wrap">
                  <div class="icon">
                     <i class="bx bxl-google-cloud green-clr-bg"></i>
                  </div>
                  <h4 class="number">05</h4>
                  <h4><a>MQTT with AWS</a>
                     
                  </h4>
                  <p>MQTT (Message Queuing Telemetry Transport) in AWS IoT Core supports device connections using MQTT and MQTT over WSS protocols, identified by a client ID
                  </p>
                
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
               <div class="box-wrap">
                  <div class="icon">
                     <i class="fa fa-commenting blue-clr-bg"></i>
                  </div>
                  <h4 class="number">06</h4>
                  <h4><a> Message Routing</a>
                  </h4>
                  <p>Message routing in Amazon Pinpoint is determined by type (promotional or transactional). You specify the route using the optional parameter in the SendMessages operation of the API
                  </p>
                 
               </div>
            </div>
            
            <div class="col-lg-4 col-md-6 mt-4">
               <div class="box-wrap">
                  <div class="icon">
                     <i class="fa fa-low-vision blue-clr-bg"></i>
                  </div>
                  <h4 class="number">07</h4>
                  <h4><a> Amazon Lookout for Vision</a>
                  </h4>
                  <p>Spot product defects using computer vision to automate quality inspection
                     A machine learning service that uses computer vision to automate visual inspection of product defects
                  </p>
                 
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
               <div class="box-wrap">
                  <div class="icon">
                     <i class="fa fa-line-chart primary-clr-bg"></i>
                  </div>
                  <h4 class="number">08</h4>
                  <h4><a> Amazon Lookout for Metrics</a>
                  </h4>
                  <p>Effortlessly spot data anomalies using a machine learning service that continuously analyzes your business data with Amazon's technology, requiring no machine learning expertise
                  </p>
                 
               </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-4">
               <div class="box-wrap">
                  <div class="icon">
                     <i class="fa fa-wrench green-clr-bg"></i>
                  </div>
                  <h4 class="number">09</h4>
                  <h4><a> Amazon Lookout for Equipment</a>
                  </h4>
                  <p>Machine learning
                     Amazon Lookout for Equipment
                     Detect abnormal equipment behavior by analyzing sensor data                     
                  </p>
                 
               </div>
            </div>
         </div>
        </div>
     </section>
    </div>

    

    <div class="gcp-section yb-section">
       <div class="container">
          <h2 class="section-heading"> Walk into<span class="gradient-word"> Future</span> with Confidence</h2>
          <div class="short-detail">Our industry leading solutions, powered by Amazon Web Services, ensures that your business is equipped with cutting-edge technology and scalability to adapt to evolving industry landscapes. Stay ahead of the curve, optimize your operations, and unlock new possibilities with LeanQubit AWS Solutions.</div>
          <div class="gcp-boxes">
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introIcon_webBasedDeployment.png" data-src="assets\img\OptebizImage\introIcon_webBasedDeployment.png" alt="Web-Based Deployment" title="Web-Based Deployment" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>On-premises data collection, processing, and monitoring</h3>
                   <div class="short-detail">AWS IoT SiteWise, including SiteWise Edge, collects, processes, and monitors equipment data locally on on-premises hardware before securely sending it to AWS. This facilitates efficient data management for industrial applications.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introIcon_rapidDevelopment.png" data-src="assets\img\OptebizImage\introIcon_rapidDevelopment.png" alt="Rapid Development" title="Rapid Development" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Rapid Development</h3>
                   <div class="short-detail"> Utilize a comprehensive set of deployment options for geo-distributed and hybrid environments. LeanQubit can be deployed across multiple AWS availability zones and regions (or even across clouds) using sync and async replication topologies.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introIcon_ITStandards.png" data-src="assets\img\OptebizImage\introIcon_ITStandards.png" alt="Built on Trusted IT standards" title="Built on Trusted IT standards" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Security and Compliance</h3>
                   <div class="short-detail"> AWS Cloud is that it allows you to scale and innovate, while maintaining a secure environment and paying only for the services you use. This means that you can have the security you need at a lower cost than in an on-premises environment.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introicon-data-collection-analytics.png" data-src="assets\img\OptebizImage\introicon-data-collection-analytics.png" alt="Data Collection &amp; Analytics" title="Data Collection &amp; Analytics" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Serverless Cloud  &amp; Functions</h3>
                   <div class="short-detail"> Get started on AWS in minutes with no software installation, configuration, or management with LeanQubit Managed. Or, if you prefer, we can provide you with all the tools you need to run a self-managed AWS yourself.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introicon-automation.png" data-src="assets\img\OptebizImage\introicon-automation.png" alt="Automation" title="Automation" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Flexible schema management</h3>
                   <div class="short-detail"> Amazon Cloud Directory allows flexible schema management for easily extending directory attributes and relationships. This supports multiple independent applications, promoting shared directories and eliminating data duplication.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introIcon_rapidDevelopment.png" data-src="assets\img\OptebizImage\introIcon_rapidDevelopment.png" alt="Reduce Development Time" title="Reduce Development Time" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Reduce Development Time</h3>
                   <div class="short-detail"> LeanQubit FactoVision on AWS accelerates deployment, leveraging the scalability and efficiency of AWS for a swift and optimized implementation. Experience the benefits of our solutions quickly and seamlessly.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\IntroIcon-Sepasoft-BetterAnalyze.svg" data-src="assets\img\OptebizImage\IntroIcon-Sepasoft-BetterAnalyze.svg" alt="Analyze &amp; Increase Performance" title="Analyze &amp; Increase Performance" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Analyze &amp; Increase Performance</h3>
                   <div class="short-detail"> As an AWS PrivateLink Service Ready Partner, LeanQubit enhances the security and privacy of workloads by connecting your Virtual Private Cloud (VPC) to your services through the AWS Private Network.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introicon-scalable-architecture.png" data-src="assets\img\OptebizImage\introicon-scalable-architecture.png" alt="Scalable Architecture" title="Scalable Architecture" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Architecting for Reliable Scalability</h3>
                   <div class="short-detail"> Cloud architects should design solutions that accommodate current needs and anticipate future scalability, whether it's organic growth or rapid expansion due to events like mergers and acquisitions.</div>
                </div>
             </div>
             
             
          </div>
       </div>
    </div>
    <div class="pdf-download-section yb-section dark-bg">
       <div class="c-v2-optebiz-section-triangle"></div>
       <div class="container">
          <div class="content-area">
             <div class="left-area">
                <h2 class="section-heading"> Certified by AWS <span><img src="assets\img\OptebizImage\aws-qualifed-software.png" data-src="assets\img\OptebizImage\aws-qualifed-software.png" alt="aws-qualifed-software" title="aws-qualifed-software" width="100" height="100" ></span><br><span class="gradient-word">For Implementing our Standardized & Custom Solutions</span></h2>
                <div class="short-detail"> LeanQubit is a Certified Integrator on AWS. Our status ensures you are working with experts who can understand the fundamentals of your business, and effectively apply cloud-based solutions to fit your requirements. We have extensive exposure to manufacturing, energy, and utility industries, giving us the experience to build custom solutions for your operation, big or small.</div>
             </div>
             <div class="c-columns c-v2-optebiz-section-img c-center-text">
               <img src="assets\img\OptebizImage\monitor-wind-farm-dashboard-console.png" data-src="assets\img\OptebizImage\monitor-wind-farm-dashboard-console.png" alt="" width="550" height="703" class="loaded come-in">
              <br>
              <br>
               <div class="gradient-word"> IOT Sitewise Dashboard</div>
               <div class="gradient-word"> <span> Dashboards provide </span>  a set of visualizations for the values of a set of assets</div>
            </div>
          </div>
       </div>
       <div class="connector"></div>
    </div>
    
    <div class="explore-further come-out yb-section dark-bg loaded come-in">
       <div class="c-v2-optebiz-section-triangle"></div>
       <div class="container">
          <h2 class="section-heading"> <span class="gradient-word">IOT </span>Architecture on AWS</h2>
          <div class="detail-sec">
             <p class="short-detail"><span style="font-weight: 400;">LeanQubit is a Certified Integrator of AWS. Our status ensures you are working with experts who can understand the fundamentals of your business, and effectively apply Cloud-based Saas solutions to fit what you needs. </span></p>
             <p class="short-detail"><span style="font-weight: 400;">We have worked in manufacturing, energy, and utility industries, giving us the experience to build custom solutions for your operation, big or small. We maintain our certification for the latest software of AWS.</span></p>
          </div>
          <div class="c-columns c-v2-optebiz-section-img c-center-text">
            <img src="assets\img\OptebizImage\aws last Flow2.svg" data-src="assets\img\OptebizImage\aws last Flow2.svg" alt="AWS SiteWise"   class="rotate90">
            
         </div>
       </div>
    </div>
 </div>



